<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{ $t('reward.title') }}</p>
        </div>
        <div class="RightBox">
          <div class="icome-list-history">
            <h5 style="color: #fff; margin-top: 0.5em;">{{ $t('reward.tableTitle1') }}</h5>
            <table id="users" class="reward-table" aria-describedby="">
              <colgroup>
                <col style="width: 33%"/>
                <col style="width: 33%"/>
                <col style="width: 33%"/>
              </colgroup>
              <thead>
              <tr>
                <th>{{$t('reward.table1th1')}}</th>
                <th>{{$t('reward.table1th2')}}</th>
                <th>{{$t('reward.table1th3')}}</th>
              </tr>
              </thead>
              <tbody>
                <tr v-for="plan in planList">
                  <td>{{plan.title}}</td>
                  <td v-html="plan.describe"></td>
                  <td>{{plan.balance}} USDT</td>
                </tr>
              </tbody>
            </table>
            <p class="reward-desc">※ {{$t('reward.desc1')}}</p>
            <br/>
            <h5 style="color: #fff; margin-top: 0.5em;">{{ $t('reward.tableTitle4') }}</h5>
            <table id="users" class="reward-table" aria-describedby="">
              <thead>
              <tr>
                <th>{{$t('reward.table4th1')}}</th>
                <th>{{$t('reward.table4th2')}}</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('reward.table4td1')}}</td>
                  <td>10000 USDT</td>
                </tr>
                <tr>
                  <td>{{$t('reward.table4td2')}}</td>
                  <td>20000 USDT</td>
                </tr>
                <tr>
                  <td>{{$t('reward.table4td3')}}</td>
                  <td>50000 USDT</td>
                </tr>
                <tr>
                  <td>{{$t('reward.table4td4')}}</td>
                  <td>100000 USDT</td>
                </tr>
              </tbody>
            </table>
            <br/>
            <h5 style="color: #fff; margin-top: 0.5em;">{{ $t('reward.tableTitle2') }}</h5>
            <table id="users" class="reward-table" aria-describedby="">
              <colgroup>
                <col style="width: 17%"/>
                <col style="width: 16%"/>
                <col style="width: 16%"/>
                <col style="width: 16%"/>
                <col style="width: 16%"/>
                <col style="width: 16%"/>
              </colgroup>
              <thead>
              <tr>
                <th></th>
                <th>{{$t('grade.grade0')}}</th>
                <th>{{$t('grade.grade1')}}</th>
                <th>{{$t('grade.grade2')}}</th>
                <th>{{$t('grade.grade3')}}</th>
                <th>{{$t('grade.grade4')}}이상</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('reward.table2td1')}}</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                </tr>
                <tr>
                  <td rowspan="2">{{$t('reward.table2td2')}}</td>
                  <td>1대</td>
                  <td>2대</td>
                  <td>3대</td>
                  <td>4대</td>
                  <td>5대</td>
                </tr>
                <tr>
<!--                  <td></td>-->
                  <td>1{{$t('reward.depthUnit')}} 20%</td>
                  <td>1{{$t('reward.depthUnit')}} 20%<br/>2{{$t('reward.depthUnit')}} 5%</td>
                  <td>1{{$t('reward.depthUnit')}} 20%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 5%</td>
                  <td>1{{$t('reward.depthUnit')}} 20%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 5%<br/>4{{$t('reward.depthUnit')}} 5%</td>
                  <td>1{{$t('reward.depthUnit')}} 20%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 5%<br/>4{{$t('reward.depthUnit')}} 5%<br/>5{{$t('reward.depthUnit')}} 5%</td>
                </tr>
              </tbody>
            </table>
            <table v-show="false" id="users" class="reward-table" aria-describedby="">
              <colgroup>
                <col style="width: 20%"/>
                <col style="width: 20%"/>
                <col style="width: 20%"/>
                <col style="width: 20%"/>
                <col style="width: 20%"/>
              </colgroup>
              <thead>
              <tr>
                <th></th>
                <th>{{$t('grade.grade5')}}</th>
                <th>{{$t('grade.grade6')}}</th>
                <th>{{$t('grade.grade7')}}</th>
                <th>{{$t('grade.grade8')}}</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('reward.table2td1')}}</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                  <td>1000</td>
                </tr>
                <tr>
                  <td rowspan="2">{{$t('reward.table2td2')}}</td>
                  <td>6대</td>
                  <td>7대</td>
                  <td>8대</td>
                  <td>9대</td>
                </tr>
                <tr>
<!--                  <td></td>-->
                  <td>1{{$t('reward.depthUnit')}} 10%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 3%<br/>4{{$t('reward.depthUnit')}} 2%<br/>5{{$t('reward.depthUnit')}} 1%<br/>6{{$t('reward.depthUnit')}} 1%</td>
                  <td>1{{$t('reward.depthUnit')}} 10%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 3%<br/>4{{$t('reward.depthUnit')}} 2%<br/>5{{$t('reward.depthUnit')}} 1%<br/>6{{$t('reward.depthUnit')}} 1%<br/>7{{$t('reward.depthUnit')}} 1%</td>
                  <td>1{{$t('reward.depthUnit')}} 10%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 3%<br/>4{{$t('reward.depthUnit')}} 2%<br/>5{{$t('reward.depthUnit')}} 1%<br/>6{{$t('reward.depthUnit')}} 1%<br/>7{{$t('reward.depthUnit')}} 1%<br/>8{{$t('reward.depthUnit')}} 1%</td>
                  <td>1{{$t('reward.depthUnit')}} 10%<br/>2{{$t('reward.depthUnit')}} 5%<br/>3{{$t('reward.depthUnit')}} 3%<br/>4{{$t('reward.depthUnit')}} 2%<br/>5{{$t('reward.depthUnit')}} 1%<br/>6{{$t('reward.depthUnit')}} 1%<br/>7{{$t('reward.depthUnit')}} 1%<br/>8{{$t('reward.depthUnit')}} 1%<br/>9{{$t('reward.depthUnit')}} 1%</td>
                </tr>
              </tbody>
            </table>
            <p class="reward-desc">※ {{$t('reward.desc2')}}</p>
            <br/>
            <h5 style="color: #fff; margin-top: 0.5em;">{{ $t('reward.tableTitle3') }}</h5>
            <table id="users" class="reward-table" aria-describedby="">
              <thead>
              <tr>
                <th>{{$t('reward.table3th1')}}</th>
                <th>{{$t('reward.table3th2')}}</th>
                <th v-show="false">{{$t('reward.table3th3')}}</th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{$t('grade.grade1')}}</td>
                  <td>{{$t('reward.table3td1')}}</td>
                  <td v-show="false">40%</td>
                </tr>
                <tr>
                  <td>{{$t('grade.grade2')}}</td>
                  <td>{{$t('reward.table3td2')}}</td>
                  <td v-show="false">40%</td>
                </tr>
                <tr>
                  <td>{{$t('grade.grade3')}}</td>
                  <td>{{$t('reward.table3td3')}}</td>
                  <td v-show="false">20%</td>
                </tr>
                <tr>
                  <td>{{$t('grade.grade4')}}</td>
                  <td>{{$t('reward.table3td4')}}</td>
                  <td v-show="false">15%</td>
                </tr>
                <tr v-show="false">
                  <td>{{$t('grade.grade5')}}</td>
                  <td>{{$t('reward.table3td5')}}</td>
                  <td v-show="false">10%</td>
                </tr>
                <tr v-show="false">
                  <td>{{$t('grade.grade6')}}</td>
                  <td>{{$t('reward.table3td6')}}</td>
                  <td v-show="false">8%</td>
                </tr>
                <tr v-show="false">
                  <td>{{$t('grade.grade7')}}</td>
                  <td>{{$t('reward.table3td7')}}</td>
                  <td v-show="false">5%</td>
                </tr>
                <tr v-show="false">
                  <td>{{$t('grade.grade8')}}</td>
                  <td>{{$t('reward.table3td8')}}</td>
                  <td v-show="false">2%</td>
                </tr>
              </tbody>
            </table>
            <p class="reward-desc" v-show="false">※ {{$t('reward.desc3')}}</p>
          </div>
          <div id="pageArea" class="cont_bottomWrap">
            <div class="paging" style="text-align: center; display: none;"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import CryptoJS from "crypto-js";

export default {
  data() {
    return {
      myPlan: '',
      planList: []
    }
  },
  mounted() {
    this.getPlanList();
  },
  methods: {
    getPlanList() {
      this.$axios.post('/member/plan/GetPlanList', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.planList = body.list;
                this.myPlan = body.plan;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.icome-list-history {
  .reward-table {
    font-family: "Trebuchet MS", Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    margin: 12px 0;
    border-radius: 15px;
  }

  .reward-table > th {
    border: 1px solid #eeeeee;
  }

}

.reward-desc {
  margin-top: 0.5em;
  text-align: right;
  color: yellow
}

@media (min-width: 300px) {

  .hide {
    display: none !important;
  }
  .small-add {
    max-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@media (min-width: 700px) {

  .hide {
    //   display: flex !important;
    margin: auto;
    //   display: block !important;
    display: table-cell !important;
    text-align: center;
  }
  .small-add {
    max-width: 100%;
  }
}

.show-mobile-menu {
  right: 0;
}

.hide-mobile-menu {
  right: 1;
}
</style>
